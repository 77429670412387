export enum VRCResponseResultStatus {
    ACCEPTED = 'accepted', // vrc accepted or vrc data ok
    INVALID = 'invalid', // invalid
    NON_ELECTRIC = 'non-electric', // non-electric vehicle code
    NON_TWO_LANE = 'non-two-lane', // not a two-lane vehicle
    DUPLICATE_USER = 'duplicate-user', // already in database (same user)
    DUPLICATE = 'duplicate', // already in database (another user)
    REGISTRATION_DATE_NOT_FOUND = 'registration-date-not-found', // registration date was not found
    REGISTRATION_DATE_INVALID = 'registration-date-invalid', // registration date invalid (e.g. > car's year)
    INSTADRIVE_EASING_CAR = 'instadrive-easing-car', // it's our car, not possible
    OESD_DATA_NOT_FOUND = 'oesd-data-not-found', // error while parsing oesd response, card number/license plate invalid
}
